// @file get current library info
import { DOMContentLoadedPromise } from '@@/bits/dom'
import { ww } from '@@/bits/global'
import type { Library } from '@@/types'

// The Library might not be set on `window` if the scripts have not run.
const getCurrentLibrary = (): Library =>
  ww?.vueStartingState?.library ?? ww?.vueStartingState?.currentLibrary ?? { id: null }

// Initialize with a potentially unsafe value, then update when the DOM is ready.
let currentLibrary = getCurrentLibrary()
export const currentLibraryPromise = DOMContentLoadedPromise.then(getCurrentLibrary)
void currentLibraryPromise.then((library) => (currentLibrary = library))

export default currentLibrary
